.card {
  position: relative;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 4px 4px;
  width: 240px;
  margin-bottom: 200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.card::after {
  content: "";
  position: absolute;
  bottom: -18x;
  left: 108px;
  border-width: 10px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}
.card::before {
  content: "";
  position: absolute;
  bottom: -18px;
  left: 108px;
  border-width: 11px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.username {
  font-size: 13px;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  cursor: pointer;
}

.username:hover {
  text-decoration: underline;
  text-decoration-color: black;
}

.card-content {
  font-size: 12px;
  color: rgb(15, 211, 187);
  margin: 2px;
  cursor: pointer;
}

.card-content:hover {
  text-decoration: underline;
  text-decoration-color: rgb(15, 211, 187);
}

.footer-content {
  font-size: 12px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price {
  font-size: 12px;
  color: #e85347;
  font-weight: bold;
  margin-left: 2px;
}
.rating-container {
  padding: 2px 4px;
}
.rating {
  /* background-color: #f0d94e;
  color: #fff; */
  /* float: right; */
}
.rating-value {
  color: #f0d94e;
  font-weight: 600;
}

.profile-img {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: 50%;
}
